import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';  // Ensure Navbar.js exists here
import Login from './components/Login';    // Ensure Login.js exists here
import Dashboard from './components/Dashboard'; // Ensure Dashboard.js exists here
import Contract from './components/Contract';
import ProtectedRoute from './components/Utils/ProtectedRoute'; // Import the ProtectedRoute component
import Test from './components/Test';
import Customer from './components/Customer';
import 'bootstrap/dist/css/bootstrap.min.css';
import SearchForm from './components/SearchForm';
import ClientVerification from './components/Customer/ClientVerification';
import ClientView from './components/ClientView';
import Agent from './components/Agent';
import Dropdown from './components/Dropdown';
import AdminNavbar from './components/AdminNavbar';
import AdminDashboard from './components/Dashboard-admin';
import ManageCompany from './components/ManageCompany';
import ManageBranches from './components/ManageBranch';
import FrenchieNavbar from './components/FrenchieNavbar';
import FrenchieDashboard from './components/Dashboard-Frenchie';
import FrenchieAgent from './components/FrenchieAgents';
import FrenchiesBranches from './components/FrenchiesBranch';
import Teams from './components/Teams';

const App = () => {
    const userRole = localStorage.getItem('role');
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} /> {/* Login route */}
                
                <Route
                    path="/dashboard"
                    element={
                        <ProtectedRoute allowedRoles={['Agent']}>
                            <Navbar /> {/* Navbar will be displayed on the Dashboard */}
                            <Dashboard />
                        </ProtectedRoute>
                    }
                />
                 <Route
                    path="/dashboard-admin"
                    element={
                        <ProtectedRoute allowedRoles={['Admin']}>
                            <AdminNavbar /> 
                            <AdminDashboard />
                        </ProtectedRoute>
                    }
                />
                
                <Route
                    path="/Contract"
                    element={
                        <ProtectedRoute allowedRoles={['Agent']}>
                            <Navbar /> {/* Navbar will be displayed on the Contract page */}
                            <Contract />
                         </ProtectedRoute>
                    }
                />
                <Route
                    path="/customer/:customerId/contract/:contractId"
                    element={
                        <ProtectedRoute allowedRoles={['Agent']}>
                            <Navbar /> {/* Navbar will be displayed on the Contract page */}
                            <Customer />
                         </ProtectedRoute>
                    }
                />
                <Route
                    path="/customer"
                    element={
                        <ProtectedRoute allowedRoles={['Agent']}>
                            <Navbar /> {/* Navbar will be displayed on the Contract page */}
                            <Customer />
                         </ProtectedRoute>
                    }
                />
                {userRole === 'Admin' && (
                <Route
                    path="/admin/SearchForm"
                    element={
                        <ProtectedRoute allowedRoles={['Admin']}>
                            <AdminNavbar />
                            <SearchForm />
                        </ProtectedRoute>
                    }
                />
            )}
            {userRole === 'Agent' && (
                <Route
                    path="/SearchForm"
                    element={
                        <ProtectedRoute allowedRoles={['Agent']}>
                            <Navbar />
                            <SearchForm />
                        </ProtectedRoute>
                    }
                />
            )}
            {userRole === 'Frenchie' && (
                <Route
                    path="/frenchie/SearchForm"
                    element={
                        <ProtectedRoute allowedRoles={['Frenchie']}>
                            <FrenchieNavbar />
                            <SearchForm />
                        </ProtectedRoute>
                    }
                />
            )}
                 <Route
                    path="/ClientVerification/:customerId/contract/:contractId"
                    element={
                       
                            <ClientVerification />
                     }
                />
                <Route
                    path="/ClientView/:customerId/contract/:contractId"
                    element={
                       
                      <ClientView />
                     }
                />
                <Route
                    path="/admin/Agents"
                    element={
                        <ProtectedRoute allowedRoles={['Admin']}>
                            <AdminNavbar /> 
                            <Agent />
                         </ProtectedRoute>
                    }
                />
                 <Route
                    path="/admin/masterdata"
                    element={
                        <ProtectedRoute allowedRoles={['Admin']}>
                            <AdminNavbar /> 
                            <Dropdown />
                         </ProtectedRoute>
                    }
                />
                 <Route
                    path="/admin/managecompany"
                    element={
                        <ProtectedRoute allowedRoles={['Admin']}>
                            <AdminNavbar /> 
                            <ManageCompany />
                         </ProtectedRoute>
                    }
                />
                
                <Route
                    path="/admin/managebranches"
                    element={
                        <ProtectedRoute allowedRoles={['Admin']}>
                            <AdminNavbar />
                            <ManageBranches />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/frenchie/managebranches"
                    element={
                        <ProtectedRoute allowedRoles={['Frenchie']}>
                            <FrenchieNavbar /> 
                            <FrenchiesBranches />
                         </ProtectedRoute>
                    }
                />
           
          
                 <Route
                    path="/frenchie/dashboard"
                    element={
                        <ProtectedRoute allowedRoles={['Frenchie']}>
                            <FrenchieNavbar /> 
                            <FrenchieDashboard />
                         </ProtectedRoute>
                    }
                />
                 <Route
                    path="/frenchie/agents"
                    element={
                        <ProtectedRoute allowedRoles={['Frenchie']}>
                            <FrenchieNavbar /> 
                            <FrenchieAgent />
                         </ProtectedRoute>
                    }
                />
                 <Route
                    path="/Teams"
                    element={
                        <ProtectedRoute allowedRoles={['Agent']}>
                            <Navbar /> 
                            <Teams />
                         </ProtectedRoute>
                    }
                />
                
            </Routes>
        </Router>
    );
};

export default App;
